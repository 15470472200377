// extracted by mini-css-extract-plugin
export var ArtistDescription = "HerveDiRosa-module--ArtistDescription--i7J34";
export var ArtistInfos = "HerveDiRosa-module--ArtistInfos--CZmY5";
export var ButtonWrapper = "HerveDiRosa-module--ButtonWrapper --pbdvI";
export var CardWrapper = "HerveDiRosa-module--CardWrapper--vMqhb";
export var CarrouselWrapper2 = "HerveDiRosa-module--CarrouselWrapper2--qAb0P";
export var Citations = "HerveDiRosa-module--Citations--aEv8b";
export var DescriptionWrapper = "HerveDiRosa-module--DescriptionWrapper--neTK5";
export var ImageWrapper = "HerveDiRosa-module--ImageWrapper--JyVmu";
export var LinkWrapper = "HerveDiRosa-module--LinkWrapper--s3RCA";
export var MobileProtrait = "HerveDiRosa-module--MobileProtrait--1nQat";
export var More = "HerveDiRosa-module--More--zidCn";
export var MoreButton = "HerveDiRosa-module--MoreButton--VWEkh";
export var NameWrapper = "HerveDiRosa-module--NameWrapper--c9THi";
export var PdpWrapper = "HerveDiRosa-module--PdpWrapper--CbBty";
export var PhotosWrapper = "HerveDiRosa-module--PhotosWrapper--DVdVA";
export var ProfilWrapper = "HerveDiRosa-module--ProfilWrapper--C5U4J";
export var TitleWrapper = "HerveDiRosa-module--TitleWrapper--ZOMEl";
export var Wrapper = "HerveDiRosa-module--Wrapper--akI7Y";